import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';

import type { Channel } from '@eeedo/types';
import type { AxiosError } from 'axios';

import ChannelApi from '../api/ChannelApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_CHANNEL_TYPES_FAILURE, FETCH_CHANNEL_TYPES_SUCCESS, PATCH_CHANNELS_SUCCESS } from './index';
import { initialRequestFailure, initialRequestInit, initialRequestSuccess } from './initActions';

export const loadChannelTypesSuccess = (channelTypes: Channel[]) => {
  return { type: FETCH_CHANNEL_TYPES_SUCCESS, payload: { channelTypes } };
};

export const loadChannelTypesFailure = (error: Error) => {
  return {
    type: FETCH_CHANNEL_TYPES_FAILURE,
    payload: { type: 'channelTypes', error: error }
  };
};

export const patchChannelSuccess = (channel: Channel) => {
  return { type: PATCH_CHANNELS_SUCCESS, channel };
};

export const fetchChannelTypes = createAsyncThunk<void, void, { rejectValue: Error }>(
  'fetchChannelTypes',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_CHANNEL_TYPES' }));
    dispatch(initialRequestInit('channelTypes'));
    try {
      const channelTypes = await ChannelApi.getChannelTypes();
      dispatch(loadChannelTypesSuccess(channelTypes));
      dispatch(initialRequestSuccess('channelTypes'));
    } catch (error) {
      console.error('Failed to load channel types', (error as Error).message);
      if (isAxiosError(error)) {
        dispatch(loadChannelTypesFailure(error as AxiosError));
      }
      dispatch(initialRequestFailure('channelTypes', error));
    }
  }
);

export const patchChannel = createAsyncThunk<void, { id: number; data: Channel }, { rejectValue: Error }>(
  'patchChannel',
  async (payload, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'PATCH_CHANNEL' }));
    await ChannelApi.patchChannel(payload);
  }
);
