import { t } from 'i18next';
import iziToast from 'izitoast';
import { throttle, uniq } from 'lodash-es';
import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

import type { PhoneConfiguration, TicketType } from '@eeedo/types';

import { Task } from 'src/api/Task';
import {
  getPhoneConfigTags,
  getPhoneConfigTicketType,
  parsePhoneConfiguration,
  shouldCreateAPhoneCallTicket
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';

import type { CallRequestPayload } from 'src/actions/phoneActions';

interface ElisaRingProps {
  ticketTypes: TicketType[];
  url: string;
  phoneConfigurations: PhoneConfiguration[];
  callRequests: CallRequestPayload[];
  userData: any;
  isOpen: boolean;
  noAccordion?: boolean;

  handleIncomingPhoneCallElisaRing(
    number: string,
    UID: string,
    ticketData: object,
    searchObject: object,
    serviceName: string | null
  ): Promise<void>;

  removeCallRequest: (ticketId: number, phoneNumber: string) => void;
  setIsOpen: (active: boolean) => void;
}

interface ElisaRingState {
  lastCallID: null | string;
  lastCallNumber: null | string;
  eventService: null | string;
}

interface RingCallEvent {
  lineID: string;
  callID: string;
  state: string;
  stateCause: string;
  number: string;
  viaLabel: string;
  viaNumber: string;
  acdGroupID: string;
  service: string;
  terminal: string;
  canDrop: boolean;
  canAnswer: boolean;
}

class ElisaRing extends React.Component<ElisaRingProps, ElisaRingState> {
  timeout: NodeJS.Timer;

  constructor(props: ElisaRingProps) {
    super(props);

    this.state = {
      lastCallID: null,
      lastCallNumber: null,
      eventService: null
    };

    this.recognizeEvent = throttle(this.recognizeEvent, 2500).bind(this);
  }

  componentWillMount() {
    window.addEventListener('message', this.handleRingEvents);
    window['__testElisaRing'] = (phoneNumber: string) => {
      if (!phoneNumber) {
        console.error('Please specify phoneNumber');
        return;
      }
      const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
      this.handleIncomingPhoneCall(normalizedPhoneNumber);
    };
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleRingEvents);
    delete window['__testElisaRing'];
  }

  private recognizeEvent = (eventData: RingCallEvent) => {
    const callRequest = this.props.callRequests.find((request) => {
      // format numbers so they match!
      const callRequestNumber = normalizePhoneNumber(request.phoneNumber);
      const eventNumber = normalizePhoneNumber(eventData.number);
      return callRequestNumber === eventNumber;
    });
    const customerPhoneNumber = normalizePhoneNumber(eventData.number);
    console.debug('[ElisaRingEvents]: Accepted event from Elisa Ring', customerPhoneNumber, eventData);
    this.setState(
      {
        lastCallID: eventData.callID,
        lastCallNumber: eventData.number,
        eventService: eventData.service
      },
      () => {
        if (!callRequest) {
          // if event is NOT found in callRequests -> create new ticket
          this.handleIncomingPhoneCall(customerPhoneNumber);
          console.debug('[ElisaRingEvents]: Creating a new ticket', this.state.lastCallID);
        } else {
          // if event IS found in callRequests -> add outward call comment to that ticket
          console.debug('[ElisaRingEvents]: Creating a new comment on', callRequest.ticketId);
          this.handleOutgoingPhoneCall(customerPhoneNumber, callRequest.ticketId);
        }
        this.timeout = setTimeout(() => {
          if (this.state.lastCallNumber === eventData.number) {
            this.setState({
              lastCallNumber: null
            });
          }
        }, 60000);
      }
    );
  };

  private handleRingEvents = (event: MessageEvent) => {
    if (typeof event.data !== 'undefined' && typeof event.data.type !== 'undefined') {
      const { data } = event;
      switch (data.type) {
        case 'CALL_EVENT': {
          if (!Array.isArray(data.content)) {
            // Ignoring data.
          } else {
            // Instead of handling just the first event, let's handle other events too if the first one does not return anything.
            const integratedEvent: RingCallEvent = data.content.find((eventData: RingCallEvent) => {
              if (!eventData) {
                console.log('[ElisaRingEvents]: event had no data');
                return false;
              }

              console.debug('[ElisaRingEvents]: ', eventData, this.state);
              const isTalking = eventData.state === 'talking';
              const isNotUnhold = eventData.stateCause !== 'causeUnhold';
              const canAnswer = eventData.canAnswer;
              const canDrop = eventData.canDrop;
              const differentCallIDs = this.state.lastCallID !== eventData.callID;
              const differentPhonenumbers = this.state.lastCallNumber !== eventData.number;

              const configuration = this.getPhoneConfiguration(eventData.service);

              // Ring conditions
              const fitsIntoRingingConditions =
                configuration?.configuration.newTicketOnRinging &&
                !isTalking &&
                !isNotUnhold &&
                canAnswer &&
                differentCallIDs &&
                differentPhonenumbers;
              // Answer conditions
              const fitsIntoAnsweringConditions =
                isTalking && !canAnswer && canDrop && differentCallIDs && differentPhonenumbers;

              if (fitsIntoRingingConditions || fitsIntoAnsweringConditions) {
                console.log('[ElisaRingEvents]: ', eventData.callID, 'should be integrated');
                this.recognizeEvent(eventData);
                return true;
              } else {
                console.log('[ElisaRingEvents]: ', eventData.callID, 'should not be integrated');
                return false;
              }
            });
            console.log('Integrated event was:', integratedEvent?.callID);
          }
          break;
        }
        default:
          // do nothing..
          break;
      }
    }
  };

  private getSearchParams = (normalizedPhoneNumber: string, ticketType: TicketType | undefined) => {
    let usedTicketType = ticketType;
    if (!ticketType) {
      usedTicketType = this.props.ticketTypes.find((tType) => {
        return tType.id === this.props.userData.userPreferences.defaultTicketType;
      });
    }
    const fieldSets = usedTicketType?.fieldSets;
    let fieldName;
    const customerFields = fieldSets?.find((field) => field.id === 'customerInfo');
    if (!customerFields) {
      throw new Error('Failed to find phonenumber field.');
    } else {
      const phoneField = customerFields?.customerInfo?.find(
        (field) =>
          field.name === 'Puhelinnumero' || field.customType === 'phoneNumber' || field.displayField === 'phoneField'
      );
      fieldName = phoneField?.object ? phoneField.object + '.' : '';
      fieldName += phoneField?.value ?? '';
    }

    const searchParams = {
      [fieldName]: normalizedPhoneNumber,
      entityTypes: ticketType?.entityRouting?.map((x) => x.entityType),
      taskType: usedTicketType?.name
    };

    return searchParams;
  };

  private getTicket = (configurationData: any) => {
    let task = new Task();

    const defaultTicketType = this.props.ticketTypes.find((tType) => {
      return tType.id === this.props.userData.userPreferences.defaultTicketType;
    });

    task.taskType =
      configurationData && configurationData?.ticketType ? configurationData?.ticketType.name : defaultTicketType?.name;
    task.tags =
      configurationData && configurationData.tags ? uniq([...configurationData.tags, ...task.tags]) : task.tags;
    task.dueDate = configurationData && configurationData.dueDate ? configurationData.dueDate : task.dueDate;
    task.channel = configurationData && configurationData.channel ? configurationData.channel : task.channel;

    task = {
      ...task,
      id: 'new_oc',
      status: 'doing',
      title: configurationData?.title || 'Elisa ring tiketti: ' + new Date().getMinutes()
    };
    return task;
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  private handleIncomingPhoneCall = (normalizedPhoneNumber: string) => {
    const configuration = this.getPhoneConfiguration();

    const parsedConfiguration = parsePhoneConfiguration(configuration);
    const configTicketType = getPhoneConfigTicketType(parsedConfiguration, this.props.ticketTypes);
    const configTags = getPhoneConfigTags(parsedConfiguration);

    const confObject = configuration
      ? {
          ...parsedConfiguration,
          ticketType: configTicketType,
          tags: configTags
        }
      : undefined;
    const ticketData = this.getTicket(confObject);
    const searchParams = this.getSearchParams(normalizedPhoneNumber, configTicketType);
    const UID = this.props.userData.UID;
    if (shouldCreateAPhoneCallTicket(configuration, parsedConfiguration)) {
      // When no configuration is found, or when configuration does not prevent creation
      this.props.handleIncomingPhoneCallElisaRing(
        normalizedPhoneNumber,
        UID,
        ticketData,
        searchParams,
        this.state.eventService
      );
    } else {
      // When there is a configuration and it prevents creation.
      iziToast.error({
        message: t('PHONECONFIGURATION_NO_TICKET'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    }
  };

  private handleOutgoingPhoneCall = (normalizedPhoneNumber: string, ticketId: number) => {
    this.props.removeCallRequest(ticketId, normalizedPhoneNumber);
  };

  private getPhoneConfiguration = (eventServiceValue?: string) => {
    const eventService = eventServiceValue || this.state.eventService;
    return this.props.phoneConfigurations.find((conf) => {
      const regexValue = eventService && eventService !== null ? eventService : '\\^NO_SERVICE_DELIVERED';
      const regex = new RegExp(regexValue, 'g');
      return conf.value?.match(regex);
    });
  };

  private renderIframe = () => (
    <iframe
      title="ElisaRing"
      id="ElisaRing"
      scrolling="yes"
      style={{ overflow: 'hidden', width: '100%', ...(this.props.noAccordion ? { height: '100vh' } : {}) }}
      height={this.props.noAccordion ? undefined : 600}
      src={this.props.url}
      frameBorder={0}
      allowFullScreen={false}
    />
  );

  render() {
    const { isOpen } = this.props;

    return this.props.noAccordion ? (
      this.renderIframe()
    ) : (
      <>
        <Accordion.Title className="ticketlist ticketlist_elisaRing" active={isOpen} onClick={this.toggleAccordion}>
          <div style={{ fontSize: '1.2em' }}>Elisa Ring</div>
          <Icon name={isOpen ? 'chevron down' : 'chevron right'} />
        </Accordion.Title>
        <Accordion.Content active={isOpen}>
          <div>{this.renderIframe()}</div>
        </Accordion.Content>
      </>
    );
  }
}

export default ElisaRing;
